import React, {
  FC, ReactElement, useCallback, useEffect, useState,
} from 'react'
import { IMenu } from 'interface/admin/menu'
import { useDispatch, useSelector } from 'react-redux'
import { requestMenu } from 'stores/admin/actions'
import { IAppState } from 'stores'
import Loader from 'react-loader-spinner'
import {
  LoaderWrap, Logo, MenuContainer, Header, Container, Content, SubMenu, MenuLink,
} from './style'
import { history } from '../../../browserHistory'
import { User } from '../userSelector/index'

interface IProps {
  title: string
  children?: Element | ReactElement
}

export const PageContainer: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch()

  const menus = useSelector((state: IAppState) => state.admin.menus)
  const [currentPath, setCurrentPath] = useState<string>()

  const getMenuByPath = useCallback(
    (key?: string): IMenu | undefined => {
      if (!key || !menus) {
        return undefined
      }
      return undefined
    },
    [menus],
  )

  useEffect(() => {
    if (!menus) {
      dispatch(requestMenu())
    }
    const path = history.location.pathname
    getMenuByPath(path)
  }, [menus, dispatch, getMenuByPath])

  useEffect(() => {
    setCurrentPath(history.location.pathname)
  }, [])

  return menus ? (
    <Container>
      <MenuContainer>
        <Logo />
        <div>
          {/* <MenuItem isSelected>
            <img src={RetailerIcon} alt="" />
            <div>Retailer</div>
          </MenuItem> */}
        </div>
      </MenuContainer>
      <div style={{ width: '100%', backgroundColor: '#2e415b' }}>
        <Header>
          <SubMenu selected>
            <MenuLink
              onClick={() => {
                history.push('/retailer/orders')
              }}
              isCurrent={currentPath === '/retailer/orders'}
            >
              Orders
            </MenuLink>
          </SubMenu>
          <User />
        </Header>
        <Content>
          {/* <Title>
            {title}
          </Title> */}
          {children}
        </Content>
      </div>
    </Container>
  ) : (
    <LoaderWrap>
      <Loader type="Oval" color="#2e5984" height={80} width={80} />
    </LoaderWrap>
  )
}
