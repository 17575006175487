import {
  filter, map, partition, includes, concat, sort, sum, ascend, prop, isNil, find,
} from 'ramda'
import { createSelector } from 'reselect'
import { IAppState } from 'stores'
import { IOrder, IProduct, IProductSnapshot } from './types'

const sumQuantities = (products: IProductSnapshot[]) => sum(products.map(({ quantity }) => Number(quantity)))

const sumPrices = (products: IProductSnapshot[]) => sum(products.map(({ quantity, price }) => Number(quantity) * Number(price)))

export const selectOrder = (state: IAppState) => state.order

export const selectFinalOrder = createSelector(selectOrder, (order) => order?.finalOrder)

export const selectCurrentOrder = createSelector(selectOrder, (order) => order?.currentOrder)

export const selectContestData = createSelector(selectOrder, (order) => order?.contestOrder)

export const selectContestDataItems = createSelector(selectContestData, (contestOrder) => contestOrder?.items || [])

export const selectContestDifferentItems = createSelector(selectContestDataItems, (contestOrderItems) =>
  filter(
    (item) => item.contestedQuantity !== item.originalQuantity || item.originalQuantity !== item.reviewedQuantity,
    contestOrderItems,
  ))

export const selectContestIds = createSelector(selectContestDifferentItems, (differentItems) =>
  map((item) => Number(item.productId), differentItems))

export const selectCurrentOrderProducts = createSelector(selectCurrentOrder, (currentOrder) => currentOrder?.products ?? [])

export const selectFinalOrderProducts = createSelector(selectFinalOrder, (finalOrder) => finalOrder?.products ?? [])

export const selectPartitionedOrderProducts = createSelector(
  selectFinalOrderProducts,
  selectContestIds,
  (finalOrderProducts, contestIds) => partition((product) => includes(Number(product.productId), contestIds), finalOrderProducts),
)
export const selectFinalOrderProductsWithContestedFirst = createSelector(
  selectPartitionedOrderProducts,
  ([finalContestItems, finalOtherItems]) =>
    concat(
      sort((product1, product2) => Number(product1.productId!) - Number(product2.productId!), finalContestItems),
      finalOtherItems,
    ),
)

export const selectOriginalTotalPrice = createSelector(selectCurrentOrderProducts, (products) =>
  Number(sumPrices(products).toFixed(2)))

export const selectReviewedTotalPrice = createSelector(selectFinalOrderProducts, (products) =>
  Number(sumPrices(products).toFixed(2)))

export const selectOriginalTotalQuantity = createSelector(selectCurrentOrderProducts, sumQuantities)

export const selectReviewedTotalQuantity = createSelector(selectFinalOrderProducts, sumQuantities)

export const selectRefundTotal = createSelector(selectOriginalTotalPrice, selectReviewedTotalPrice, (original, reviewed) =>
  Math.max(0, original - reviewed))

export const selectContestedOrder = createSelector(selectCurrentOrder, selectContestDataItems, (currentOrder, contestedItems) => {
  const items = sort(ascend(prop('productId')), contestedItems)
  const contestedItemsIds = items.map((item) => item.productId.toString())
  const removedItems = (currentOrder?.products ?? [])
    .filter((originalProduct) => !contestedItemsIds.includes(originalProduct.productId!))
    .map((product) => ({ ...product, quantity: 0 }))

  const products: IProduct[] = items
    .filter((product) => product.reviewedQuantity !== 0) // ignore contested products that didn't make the review
    .map((contest) => ({
      ...contest,
      productId: contest.productId,
      quantity: Number(contest.reviewedQuantity),
    }))

  return {
    ...currentOrder,
    products: products.concat(removedItems),
  } as IOrder
})

export const selectProductChangesForAudit = createSelector(
  selectCurrentOrderProducts,
  selectFinalOrderProducts,
  (originalProducts, finalProducts) => {
    const updatedProducts = filter((product) => {
      const originalProduct = find((p) => p.productId === product.productId, originalProducts)
      const quantityChanged = !isNil(originalProduct) && originalProduct.quantity !== product.quantity
      const wasAdded = isNil(originalProduct) && product.quantity > 0
      const wasRemoved = !isNil(originalProduct) && product.quantity === 0
      return wasAdded || wasRemoved || quantityChanged
    }, finalProducts)

    return map(
      ({ productId, quantity, categories }) => ({
        productId: Number(productId),
        quantity,
        categories: categories ? [categories] : [],
      }),
      updatedProducts,
    )
  },
)
