import React, { FC } from 'react'
import { Button, ButtonContainer, Confirm } from '../style'

interface IRefundControlsProps {
  onCancel: () => void
  onSubmit: () => void
  originalTotalPrice: number
  refundTotalPrice: number
}

export const RefundControls: FC<IRefundControlsProps> = ({
  onCancel, onSubmit, refundTotalPrice, originalTotalPrice,
}) => (
  <ButtonContainer>
    <Button onClick={onCancel}>Cancel</Button>
    <Confirm
      onClick={onSubmit}
      disabled={refundTotalPrice > originalTotalPrice}
      disabledBtn={refundTotalPrice > originalTotalPrice}
    >
      Submit
    </Confirm>
  </ButtonContainer>
)