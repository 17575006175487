import { IAuditPayload, IAudit, IGroupedAudits } from '../../interface/audits/audit'

export const FETCH_ORDER_AUDITS = 'audits/fetch_order_audits' as const
export const FETCH_AUDIT = 'audits/fetch_audit' as const
export const CREATE_AUDIT = 'audits/create_audit' as const
export const SET_AUDITS = 'audits/set_audits' as const
export const SET_AUDIT = 'audits/set_audit' as const
export const REQUEST_UPDATE_AUDIT = 'audits/request_update_audit' as const

export const fetchOrderAudits = (orderId: string | number) => ({
  type: FETCH_ORDER_AUDITS,
  payload: {
    orderId,
  },
})

export const fetchAudit = (auditId: string | number) => ({
  type: FETCH_AUDIT,
  payload: {
    auditId,
  },
})

export const createAudit = (orderId: string | number) => ({
  type: CREATE_AUDIT,
  payload: {
    orderId,
  },
})

export const setAudits = (audits: IGroupedAudits) => ({
  type: SET_AUDITS,
  payload: {
    audits,
  },
})

export const setAudit = (orderId: string | number, audit: IAudit) => ({
  type: SET_AUDIT,
  payload: {
    orderId,
    audit,
  },
})

export const requestUpdateAudit = (auditId: string | number, audit: IAuditPayload) => ({
  type: REQUEST_UPDATE_AUDIT,
  payload: {
    auditId,
    audit,
  },
})

export type IAuditsActions = ReturnType<
  | typeof fetchOrderAudits
  | typeof fetchAudit
  | typeof createAudit
  | typeof setAudits
  | typeof setAudit
  | typeof requestUpdateAudit
>
