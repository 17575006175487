/* eslint-disable no-multi-str */
import React, {
  FC, useCallback, useEffect, useState,
} from 'react'
import Arrow from 'assets/icons/arrow.svg'

import { IStore } from 'interface/admin/store'
import {
  TypeSelector, TypeOption, SelectorContainer, Text,
} from './style'

interface IProps {
  title: string
  options?: IStore[]
  selected?: IStore
  setSelected?: (value?: IStore) => void
  setPageSize: (pageSize: number) => void
  setSelector: (selector?: string) => void
  selector?: string
}

export const StoreSelector: FC<IProps> = ({
  title, options, selected, setSelected, selector, setSelector,
}) => {
  const [isSelectorShow, setIsSelectorShow] = useState<boolean>(false)

  const handleOnStoreClick = useCallback(
    (status?: IStore) => {
      setSelected && setSelected(status)
      setIsSelectorShow(false)
      setSelector()
    },
    [setSelected, setSelector],
  )

  const handleShow = useCallback(
    (e: Event) => {
      e.stopPropagation()
      setSelector('store')
      setIsSelectorShow(!isSelectorShow)
    },
    [isSelectorShow, setSelector],
  )

  useEffect(() => {
    if (selector !== 'store' && isSelectorShow) {
      setIsSelectorShow(false)
    }
  }, [selector, setSelector, isSelectorShow])

  return (
    <SelectorContainer>
      <Text onClick={handleShow}>
        {selected?.storeName || title}
        &nbsp;
        <img src={Arrow} alt="" style={{ transform: isSelectorShow ? 'rotate(180deg)' : '' }}></img>
      </Text>
      {isSelectorShow && selector === 'store' && (
        <TypeSelector id="storeSelector">
          <TypeOption key={-1} isSelected={!selected} onClick={handleOnStoreClick}>
            All
          </TypeOption>
          {options
            && options.map((store: IStore, index: number) => (
              <TypeOption
                key={index}
                isSelected={selected?.storeId === store.storeId}
                onClick={() => {
                  handleOnStoreClick(store)
                }}
              >
                {store.storeName}
              </TypeOption>
            ))}
        </TypeSelector>
      )}
    </SelectorContainer>
  )
}
