import React, { FC } from 'react'
import { OrderStatus } from 'stores/orders/types'
import { ReceiptAmount } from './components/ReceiptAmount'
import { ReceiptStatus } from './components/ReceiptStatusToggle'
import { RefundAmount } from './components/RefundAmount'
import { RefundControls } from './components/RefundControls'
import { BottomBox, RequiredMsg, SummaryWrapper } from './style'

interface IOrderSummaryProps {
  currentQuantity: number
  totalTax: number
  currencySymbol: string
  originalTotalPrice: number
  finalQuantity: number
  reviewedTotalPrice: number
  refundTotalPrice: number
  onRefundChange: (e: React.FormEvent<HTMLInputElement>) => void
  hasChanged: boolean
  onCancel: () => void
  onSubmit: () => void
  orderStatus: OrderStatus
  receiptStatus: ReceiptStatus
  issueRequired: boolean
  isInAuditMode: boolean
  showTax: boolean
  isAuditEmpty: boolean
}

export const OrderSummary: FC<IOrderSummaryProps> = ({
  currentQuantity,
  totalTax,
  currencySymbol,
  originalTotalPrice,
  finalQuantity,
  reviewedTotalPrice,
  refundTotalPrice,
  onRefundChange,
  hasChanged,
  onCancel,
  onSubmit,
  orderStatus,
  receiptStatus,
  issueRequired,
  isInAuditMode,
  showTax,
  isAuditEmpty,
}) => {
  const isPending = orderStatus === OrderStatus.CONTESTED
  const isPaid = orderStatus === OrderStatus.PAID
  const isReviewed = orderStatus === OrderStatus.REVIEWED

  const showAuditedTotal = isInAuditMode && !isAuditEmpty
  const showRevised = (receiptStatus === ReceiptStatus.REVISED && hasChanged) || showAuditedTotal

  const showRefundAmount = (isPending || isReviewed) && receiptStatus === ReceiptStatus.REVISED
  const showRefundControls = isPending && receiptStatus === ReceiptStatus.REVISED

  return (
    <SummaryWrapper>
      {issueRequired && isInAuditMode && receiptStatus === ReceiptStatus.REVISED && (
        <RequiredMsg>To submit please report any pending issue.</RequiredMsg>
      )}
      <BottomBox>
        <ReceiptAmount
          title="Original Total"
          quantity={currentQuantity}
          currencySymbol={currencySymbol}
          price={originalTotalPrice}
          darkFont={isPaid}
          lineThrough={!isPaid && hasChanged && receiptStatus === ReceiptStatus.REVISED}
        />
        {showTax && <ReceiptAmount title="Tax Total" currencySymbol={currencySymbol} price={totalTax} />}
        {showRevised && (
          <ReceiptAmount
            title="Revised Total"
            quantity={finalQuantity}
            currencySymbol={currencySymbol}
            price={reviewedTotalPrice}
            darkFont
          />
        )}
        {showRefundAmount && (
          <RefundAmount
            currencySymbol={currencySymbol}
            originalTotalPrice={originalTotalPrice}
            refundTotalPrice={refundTotalPrice}
            onRefundChange={onRefundChange}
            isPending={isPending}
          />
        )}
        {showRefundControls && (
          <RefundControls
            onCancel={onCancel}
            onSubmit={onSubmit}
            refundTotalPrice={refundTotalPrice}
            originalTotalPrice={originalTotalPrice}
          />
        )}
      </BottomBox>
    </SummaryWrapper>
  )
}
