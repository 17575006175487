import React, { FC } from 'react'
import {
  ContainerItem, CurrencySymbol, RefundContainer, RefundInput, RefundWarningTip, TotalContainer,
} from '../style'

interface IRefundAmountProps {
  currencySymbol: string
  originalTotalPrice: number
  refundTotalPrice: number
  onRefundChange: (e: React.FormEvent<HTMLInputElement>) => void
  isPending: boolean
}

export const RefundAmount: FC<IRefundAmountProps> = ({
  currencySymbol,
  originalTotalPrice,
  refundTotalPrice,
  onRefundChange,
  isPending,
}) => (
  <>
    <TotalContainer main border>
      <div>Refund Amount</div>
      <ContainerItem leftMargin align="right">
        <RefundContainer>
          {isPending ? (
            <>
              <CurrencySymbol>
                {currencySymbol}
              </CurrencySymbol>
              <RefundInput
                key={refundTotalPrice}
                type="text"
                defaultValue={refundTotalPrice}
                onBlur={onRefundChange}
                refundWarning={refundTotalPrice > originalTotalPrice}
              />
            </>
          ) : (
            <span>
              {currencySymbol}
              {refundTotalPrice.toFixed(2)}
            </span>
          )}
        </RefundContainer>
      </ContainerItem>
    </TotalContainer>
    {isPending && refundTotalPrice > originalTotalPrice && (
      <RefundWarningTip>Refund amount is not allowed to be over than origin amount</RefundWarningTip>
    )}
  </>
)