import React, {
  FC, useEffect, useState, useCallback, Dispatch, SetStateAction,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contestOrder, setFinalOrder, requstSubmitContestedOrder } from 'stores/orders/actions'
import {
  CreateOrderContestPayload, IOrder, IProductSnapshot, OrderStatus,
} from 'stores/orders/types'
import * as R from 'ramda'
import { EditIcon } from 'components/icons/edit'
import { theme } from 'theme'
import { createAudit, fetchOrderAudits, requestUpdateAudit } from 'stores/audits/actions'
import {
  selectAuditedProducts,
  selectAuditedProductsTotalPrice,
  selectAuditedProductsTotalQuantity,
  selectCurrentOrderAudit,
  selectIsCurrentOrderAuditEmpty,
} from 'stores/audits/selectors'
import { Button } from 'components/button'
import { selectHasUserAuditsRole, selectHasUserContestsRole } from 'stores/auth/selectors'
import { config } from 'config'
import {
  selectContestedOrder,
  selectFinalOrderProductsWithContestedFirst,
  selectOriginalTotalPrice,
  selectOriginalTotalQuantity,
  selectProductChangesForAudit,
  selectRefundTotal,
  selectReviewedTotalPrice,
  selectReviewedTotalQuantity,
} from 'stores/orders/selectors'
import { IAppState } from 'stores'
import { Product } from '../product'
import {
  ProductList, ReceiptDetail, DetailTitle, TitleText, Subtitle, HeaderButtons,
} from './style'
import { AuditBox } from './AuditBox'
import { SearchBar } from './components/SearchBar'
import { AuditBoxReadOnly } from './AuditBoxReadOnly'
import { AuditButton } from './AuditButton'
import { ReceiptStatus, ReceiptStatusToggle } from './components/ReceiptStatusToggle'
import { OrderSummary } from './OrderSummary'

interface IProps {
  currentOrder: IOrder
  finalOrder: IOrder
  isInAuditMode: boolean
  setIsInAuditMode: Dispatch<SetStateAction<boolean>>
}

export const Detail: FC<IProps> = ({
  finalOrder, currentOrder, isInAuditMode, setIsInAuditMode,
}) => {
  const dispatch = useDispatch()
  const [customRefund, setCustomRefund] = useState<number | null>()
  const [hasChanged, setHasChanged] = useState(false)
  const [receiptStatus, setReceiptStatus] = useState(ReceiptStatus.REVISED)
  const [issueRequired, setIssueRequired] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const contestedOrder = useSelector(selectContestedOrder)
  const finalItems = useSelector(selectFinalOrderProductsWithContestedFirst)
  const currentAudit = useSelector(selectCurrentOrderAudit)
  const hasAuditRole = useSelector(selectHasUserAuditsRole)
  const hasContestRole = useSelector(selectHasUserContestsRole)
  const isAuditEmpty = useSelector(selectIsCurrentOrderAuditEmpty)
  const auditedProducts = useSelector(selectAuditedProducts)
  const currencySymbol = useSelector((state: IAppState) => state.store.config.currency.symbol)
  const originalTotalPrice = useSelector(selectOriginalTotalPrice)
  const contestTotalPrice = useSelector(selectReviewedTotalPrice)
  const originalRefund = useSelector(selectRefundTotal)
  const currentQuantity = useSelector(selectOriginalTotalQuantity)
  const contestQuantity = useSelector(selectReviewedTotalQuantity)
  const auditTotalPrice = useSelector(selectAuditedProductsTotalPrice)
  const auditTotalQuantity = useSelector(selectAuditedProductsTotalQuantity)
  const productChangesForAudit = useSelector(selectProductChangesForAudit)

  const isContested = currentOrder.status === OrderStatus.CONTESTED
  const isPaid = currentOrder.status === OrderStatus.PAID
  const isReviewed = currentOrder.status === OrderStatus.REVIEWED
  const isInEditMode = isInAuditMode ? isAuditEmpty : isContested

  const finalQuantity = isInAuditMode && !isInEditMode ? auditTotalQuantity : contestQuantity
  const reviewedTotalPrice = isInAuditMode && !isInEditMode ? auditTotalPrice : contestTotalPrice
  const refundTotalPrice = R.isNil(customRefund) ? originalRefund : customRefund

  const totalTax = Number(currentOrder?.totalTax ?? 0)

  const { showTax } = config.misc

  useEffect(() => {
    if (JSON.stringify(currentOrder.products) !== JSON.stringify(finalOrder.products) || refundTotalPrice !== 0) {
      setHasChanged(true)
    } else {
      setHasChanged(false)
    }
  }, [currentOrder.products, finalOrder.products, refundTotalPrice])

  useEffect(() => {
    if (hasAuditRole) {
      dispatch(fetchOrderAudits(currentOrder.id))
    }
  }, [dispatch, currentOrder.id, hasAuditRole])

  const onSubmit = useCallback(() => {
    if (refundTotalPrice > originalTotalPrice) {
      return
    }
    dispatch(requstSubmitContestedOrder(originalTotalPrice - refundTotalPrice))
  }, [dispatch, refundTotalPrice, originalTotalPrice, totalTax])

  const onRefundChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCustomRefund(Number(e.currentTarget.value))
  }

  const onContestButton = useCallback(() => {
    const emptyContest: CreateOrderContestPayload = {
      orderId: currentOrder.id,
      message: '-',
      items: [],
    }
    dispatch(contestOrder(emptyContest))
  }, [dispatch, currentOrder.id])

  const onAuditButton = useCallback(() => {
    setIsInAuditMode(true)
    if (R.isNil(currentAudit)) {
      dispatch(createAudit(currentOrder.id))
    }
    dispatch(fetchOrderAudits(currentOrder.id))
  }, [dispatch, currentOrder.id, setIsInAuditMode, currentAudit])

  const resetOrderToPreAuditState = () => {
    if (isReviewed) {
      dispatch(setFinalOrder({ ...contestedOrder }))
    } else {
      dispatch(setFinalOrder({ ...currentOrder }))
    }
  }

  const submitAudit = (values: { comment: string }) => {
    const finalProducts = R.map((product) => {
      const originalProduct = R.find((p) =>
        p.productId?.toString() === product.productId?.toString(), currentOrder?.products ?? [])
      return { ...product, originalQuantity: originalProduct ? originalProduct.quantity : 0 }
    }, finalOrder.products ?? [])

    const products = R.map((p) => ({ ...p, issueRequired: p.quantity !== p.originalQuantity && !p.categories }), finalProducts)

    dispatch(setFinalOrder({ ...finalOrder, products }))

    if (R.any((p) => p.issueRequired, products)) {
      setIssueRequired(true)
      return
    }
    setIssueRequired(false)

    if (!values.comment) {
      setErrorMsg('Add comments to submit please')
      return
    }

    if (!R.isNil(currentAudit)) {
      dispatch(
        requestUpdateAudit(currentAudit.id, {
          results: productChangesForAudit,
          comment: R.isEmpty(values.comment) ? undefined : values.comment,
        }),
      )
      setIsInAuditMode(false)
    }

    resetOrderToPreAuditState()
  }

  const cancelAudit = () => {
    setIssueRequired(false)
    setIsInAuditMode(false)
    setErrorMsg('')
    resetOrderToPreAuditState()
  }

  const roundToDecimalPlaces = (value: number, places = 2) => Math.round(value * 10 ** places) / 10 ** places

  const auditedOrFinalProducts = isInAuditMode && !isInEditMode ? auditedProducts : finalItems
  const productsList = receiptStatus === ReceiptStatus.ORIGINAL ? currentOrder.products : auditedOrFinalProducts

  return (
    <ReceiptDetail>
      <DetailTitle>
        <TitleText>Receipt Details</TitleText>
        <HeaderButtons>
          {hasAuditRole && (
            <AuditButton
              isAuditEmpty={isAuditEmpty}
              isInAuditMode={isInAuditMode}
              setIsInAuditMode={setIsInAuditMode}
              onAudit={onAuditButton}
            />
          )}
          {hasContestRole && isPaid && (
            <Button
              disabled={isInEditMode}
              onClick={onContestButton}
              kind="primary"
              iconLeft={<EditIcon fillColor={theme.colors.white} />}
            >
              Contest
            </Button>
          )}
        </HeaderButtons>
      </DetailTitle>
      <Subtitle>
        {isInEditMode && (
          <>
            {hasChanged && <ReceiptStatusToggle receiptStatus={receiptStatus} setReceiptStatus={setReceiptStatus} />}
            <SearchBar currencySymbol={currencySymbol} finalOrder={finalOrder} />
          </>
        )}
      </Subtitle>
      <ProductList>
        {productsList?.map((product: IProductSnapshot) => (
          <Product
            currentOrder={currentOrder}
            isInEditMode={receiptStatus === ReceiptStatus.ORIGINAL ? false : isInEditMode}
            key={product.productId}
            product={product}
            finalOrder={finalOrder}
            currencySymbol={currencySymbol}
            cancelTips={() => setIssueRequired(false)}
            isInAuditMode={isInAuditMode}
          />
        ))}
      </ProductList>
      <OrderSummary
        currencySymbol={currencySymbol}
        currentQuantity={currentQuantity}
        finalQuantity={finalQuantity}
        totalTax={totalTax}
        originalTotalPrice={originalTotalPrice}
        reviewedTotalPrice={roundToDecimalPlaces(reviewedTotalPrice)}
        refundTotalPrice={roundToDecimalPlaces(refundTotalPrice)}
        onRefundChange={onRefundChange}
        hasChanged={hasChanged}
        onCancel={() => dispatch(setFinalOrder({ ...currentOrder }))}
        onSubmit={onSubmit}
        orderStatus={currentOrder.status}
        receiptStatus={receiptStatus}
        issueRequired={issueRequired}
        isInAuditMode={isInAuditMode}
        showTax={!!showTax}
        isAuditEmpty={isAuditEmpty}
      />
      {isInAuditMode && isAuditEmpty && (
        <AuditBox onCancel={cancelAudit} onSubmit={submitAudit} errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
      )}
      {!R.isNil(currentAudit) && isInAuditMode && !isAuditEmpty && <AuditBoxReadOnly comment={currentAudit.comment} />}
    </ReceiptDetail>
  )
}
