import * as R from 'ramda'
import * as actions from './actions'
import { IOrderState, OrderStatus } from './types'

export const initialState: IOrderState = {
  pageSize: 10,
  pageIndex: 0,
  productSelectorVisible: false,
  currentOrder: undefined,
  finalOrder: undefined,
}

export const orderReducer = (state: IOrderState = initialState, action: actions.IOrderActions) => {
  switch (action.type) {
    case actions.REQUEST_ORDERS:
      return {
        ...state,
      }
    case actions.REQUEST_ORDERS_SUCCESS:
      return {
        ...state,
        orders: (state.orders || []).concat(action.payload.data),
        pagination: action.payload.pagination,
        currentOrder: undefined,
      }
    case actions.RESET_ORDERS:
      return {
        ...state,
        orders: [],
        pagination: {},
        currentOrder: undefined,
      }
    case actions.SELECT_ORDERS_PAGE:
      return {
        ...state,
        pageIndex: action.payload.page,
        pageSize: action.payload.size,
      }

    case actions.SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: action.payload.order,
      }
    case actions.SET_FINAL_ORDER: {
      const { order } = action.payload
      const subtotalPrice = order?.products?.reduce((sum, e) => sum + Number(e.quantity) * Number(e.price), 0) || 0

      if (!order) {
        return { ...state, finalOrder: undefined }
      }
      order.totalPrice = (subtotalPrice - Number(order?.totalTax)).toFixed(2).toString()
      order.subtotalPrice = subtotalPrice?.toFixed(2).toString() || undefined

      return {
        ...state,
        finalOrder: order,
      }
    }
    case actions.REQUEST_ORDER_CASES:
      return {
        ...state,
        cases: [],
        targets: [],
      }
    case actions.REQUEST_ORDER_CASES_SUCCESS:
      return {
        ...state,
        cases: action.payload?.data?.cases ?? [],
        targets: action.payload?.data?.targets ?? [],
      }
    case actions.SWITCH_CONTEST_PRODUCT_SELECTOR_VISIBLE: {
      let productSelectorVisible = !state.productSelectorVisible
      if (action.payload.visible !== undefined) {
        productSelectorVisible = action.payload.visible
      }
      return {
        ...state,
        productSelectorVisible,
      }
    }
    case actions.REQUEST_RETAILER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        searchedProduct: {
          ...(state.searchedProduct || {}),
          items: action.payload.data,
          count: action.payload.totalCount,
        },
      }
    }
    case actions.SELECT_CONTEST_PRODUCT_PAGE: {
      return {
        ...state,
        searchedProduct: {
          ...(state.searchedProduct || {}),
          pageIndex: action.payload.page,
          pageSize: action.payload.size,
        },
      }
    }
    case actions.SET_CURRENT_ORDER_AS_CONTESTED: {
      if (R.isNil(state.currentOrder)) {
        return state
      }

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          status: OrderStatus.CONTESTED,
        },
      }
    }
    case actions.SET_CONTEST_PRODUCT: {
      return {
        ...state,
        contestOrder: action.payload.contestProduct,
      }
    }
    default:
      return state
  }
}
