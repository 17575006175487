import styled from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'
import { theme } from 'theme'
import { PrimaryStyle } from './PrimaryStyle'
import { SecondaryCautionStyle } from './SecondaryCautionStyle'
import { SecondaryStyle } from './SecondaryStyle'

export const StyledButton = styled.button`
  height: ${ifProp('small', '32px', '40px')};
  border-radius: 32px;
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: start;

  gap: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: ${ifProp('small', '14px', '16px')};
  font-weight: ${theme.fonts.weights.normal};
  font-family: Roboto;

  ${switchProp('kind', {
    primary: PrimaryStyle,
    secondary: SecondaryStyle,
    secondaryCaution: SecondaryCautionStyle,
  })};

  &:disabled {
    cursor: initial;
  }
`
