import { TableRow } from 'components/orderList/style'
import styled from 'styled-components'
import is from 'styled-is'
import { TableCell } from '../../../orderList/style'

export const BorderBox = styled.div`
  box-sizing: border-box;
  border: 1px solid #dce8ef;
  border-radius: 10px;
`

export const Row = styled(TableRow)<{ isTh?: boolean }>`
  &:hover {
    background: transparent;
  }
  padding: 5px 15px;
  border: none;
  ${is('isTh')`
   font-weight:500;
   padding: 0 15px;
   height: 48px;
   font-size: 16px;
  `}
`

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px;
  color: #2E415B;
  font-size: 14px;
  border: 1px solid #e6eef2;
  border-radius: 30px;
  cursor: pointer;
`

export const ButtonIcon = styled.img`
  width: 16px;
  height: 8px;
  margin-right: 6px;
  transform: rotate(90deg);
`

export const GeneralRow = styled(Row)`
  padding: 5px 30px;
`

export const GeneralRowHeader = styled(Row)`
  padding: 10px 30px;
  font-size: 18px;
  border-bottom: 1px solid #dce8ef;
`

export const Cell = styled(TableCell)<{ isTh?: boolean }>`
  font-family: Roboto;
  box-sizing: border-box;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #6b737d;
  font-weight: 400;
  ${is('isTh')`
    color: #31373D;
    font-weight: 500;
  `}
`

export const GeneralCell = styled(Cell)`
  color: #2e415b;
  font-weight: 400;
  text-align: left;
`

export const GeneralHeaderCell = styled(Cell)`
  color: #31373d;
  font-weight: 500;
  text-align: left;
`

export const StatusCell = styled(Cell)`
  text-align: left;
`

export const CaseIDCell = styled(Cell)`
  display: none;

  @media only screen and (min-width: 1200px) {
    display: flex;
  }
`
export const AuditLinkCell = styled(Cell)`
  width: 18px;
`