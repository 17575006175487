import { Header } from 'components/typography/Header'
import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Field, Formik } from 'formik'
import {
  BottomBox, Button as CSSButton, ButtonContainer, Confirm, RequiredMsg, Footer,
} from './style'
import { StyledTextarea } from './StyledTextarea'
import { FlexForm } from './components/FlexForm'
import { AUDIT_FORM_INITIAL_VALUES } from './const'

interface IProps {
  errorMsg: string
  setErrorMsg: (s: string) => void
  onCancel: () => void
  onSubmit: (values: { comment: string }) => void
}

export const AuditBox: FC<IProps> = ({
  errorMsg, onCancel, onSubmit, setErrorMsg,
}) => {
  const theme = useTheme()

  return (
    <>
      <BottomBox background={theme.colors.grey[200]}>
        <Header as="h4" color="primary">
          Audition Comments
          {errorMsg.length > 0 && (
            <>
              {' - '}
              <RequiredMsg>
                {errorMsg}
              </RequiredMsg>
            </>
          )}
        </Header>
        <Formik initialValues={AUDIT_FORM_INITIAL_VALUES} onSubmit={onSubmit}>
          {() => (
            <FlexForm>
              <Field
                type="text"
                as={StyledTextarea}
                name="comment"
                placeholder="Add comments here."
                caution={!!errorMsg}
                onFocus={() => setErrorMsg('')}
              >
              </Field>
              <Footer>
                <ButtonContainer>
                  <CSSButton onClick={onCancel}>Cancel</CSSButton>
                  <Confirm type="submit">Submit</Confirm>
                </ButtonContainer>
              </Footer>
            </FlexForm>
          )}
        </Formik>
      </BottomBox>
      <Header as="h5" color="caution">
        *Changes in this receipt will not be sent to the customer.
      </Header>
    </>
  )
}
