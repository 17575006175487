import { IPagination } from '../../api/common'

export enum AuditCategory {
  OPERATOR_MISTAKE = 'OPERATOR_MISTAKE',
  PLANOGRAM_ERROR = 'PLANOGRAM_ERROR',
  CAMERA_VIEW = 'CAMERA_VIEW',
  SYSTEM_EVENT_LOST = 'SYSTEM_EVENT_LOST',
  SYSTEM_HAT_ERROR = 'SYSTEM_HAT_ERROR',
  SYSTEM_REID_TRACKING = 'SYSTEM_REID_TRACKING',
  SCHEDULED_DOWNTIME = 'SCHEDULED_DOWNTIME',
  UNSCHEDULED_DOWNTIME = 'UNSCHEDULED_DOWNTIME',
  NO_ISSUE = 'NO_ISSUE',
  OTHER = 'OTHER',
  SYSTEM_IN_N_OUT = 'SYSTEM_IN_N_OUT',
  LIGHTING_ISSUE = 'LIGHTING_ISSUE',
  TESTER_ERROR_MISSING_PRODUCT = 'TESTER_ERROR_MISSING_PRODUCT',
  TESTER_ERROR_WRONG_PRODUCT = 'TESTER_ERROR_WRONG_PRODUCT',
  TESTER_ERROR_OTHER = 'TESTER_ERROR_OTHER',
}
export interface IAuditProduct {
  category: string
  id: number
  name: string
  price: string
  sku?: string
  thumbnail: string
  weight: string
}

export interface IAudit {
  id: number
  orderId: number
  contestedOrderId: number
  status: 'pending' | 'reviewed'
  comment: string
  results: [{ product: IAuditProduct, quantity: number, categories: AuditCategory }]
}

export interface IOrderAuditsResponse {
  audits: IAudit[]
  pagination: IPagination
}

export interface IGroupedAudits {
  [key: string]: IAudit[]
}

export interface IAuditPayload {
  results: { productId: string | number, quantity: number, categories: AuditCategory[] }[]
  comment?: string
}
