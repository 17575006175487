import React, { FC } from 'react'
import dayjs from 'dayjs'
import { ICase } from 'stores/receipt/types'
import { SubTitle } from 'components/orderDetail/style'
import { CameraIcon } from 'components/icons/cameraIcon'
import { useSelector } from 'react-redux';
import {
  BorderBox, Cell, Row, CaseIDCell, AuditLinkCell,
} from '../general/style'
import { ActionCount, CaseRow } from './style'
import { Container } from '../contestProduct/style'
import Link from '../../../../assets/icons/link.svg'
import { config } from '../../../../config';
import { selectHasUserAuditsRole } from '../../../../stores/auth/selectors';

interface IProps {
  data?: ICase[]
  onSelected: (caseData: ICase) => void
  sessionId: string
}

export const Cases: FC<IProps> = ({ data, onSelected, sessionId }) => {
  const hasAuditsRole = useSelector(selectHasUserAuditsRole)
  return (
    <Container>
      <SubTitle>
        Cases
        <CameraIcon fillColor="rgba(46, 65, 91, 0.5)" />
      </SubTitle>
      <BorderBox>
        <Row isTh>
          <CaseIDCell isTh>Case ID</CaseIDCell>
          <Cell isTh>Time</Cell>
          <Cell isTh>Action</Cell>
        </Row>
        {data?.map((d, index) => (
          <CaseRow key={d.caseId} isLast={index === data?.length - 1} onClick={() => onSelected(d)}>
            <CaseIDCell>
              {d.caseId}
            </CaseIDCell>
            <Cell>
              {dayjs(Number(d.createTime) * 1000).format('h:mm:ss A')}
            </Cell>
            <Cell>
              {d.operationResult?.carts?.map((cart) =>
                cart.items.map((product) => (
                  <span key={product.name}>
                    <ActionCount>
                      {product?.increment > 0 ? `+${product?.increment}` : `${product?.increment}`}
                    </ActionCount>
                    {product.name}
                  </span>
                )),
              )}
            </Cell>
            {
              hasAuditsRole && (
                <AuditLinkCell>
                  <a
                    href={`${config.hat.url}/#/${sessionId}/cases/${d.caseId} `}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={Link} alt="Go to HAT" />
                  </a>
                </AuditLinkCell>
              )
            }
          </CaseRow>
        ))}
      </BorderBox>
    </Container>
  );
}
