/* eslint-disable no-multi-str */
import React, {
  FC, useCallback, useEffect, useState,
} from 'react'
import Arrow from 'assets/icons/arrow.svg'
import { OrderStatus } from 'stores/orders/types'
import {
  TypeSelector, TypeOption, SelectorContainer, Text,
} from '../storeSelector/style'
import { getStatusString } from '../utils'

interface IProps {
  title: string
  options?: OrderStatus[]
  selected?: OrderStatus
  setSelected?: (value?: OrderStatus) => void
  setPageSize: (pageSize: number) => void
  setSelector: (selector?: string) => void
  selector?: string
}

export const StatusSelector: FC<IProps> = ({
  title, options, selected, setSelected, selector, setSelector,
}) => {
  const [isSelectorShow, setIsSelectorShow] = useState<boolean>(false)

  const handleOnStatusClick = useCallback(
    (status?: OrderStatus) => {
      setSelected && setSelected(status)
      setIsSelectorShow(false)
      setSelector()
    },
    [setSelected, setSelector],
  )
  const handleShow = useCallback(
    (e: Event) => {
      e.stopPropagation()
      setSelector('status')
      setIsSelectorShow(!isSelectorShow)
    },
    [isSelectorShow, setSelector],
  )

  useEffect(() => {
    if (selector !== 'status' && isSelectorShow) {
      setIsSelectorShow(false)
    }
  }, [selector, setSelector, isSelectorShow])

  return (
    <SelectorContainer>
      <Text onClick={handleShow}>
        {getStatusString(selected) || title}
        &nbsp;
        <img src={Arrow} alt="" style={{ transform: isSelectorShow ? 'rotate(180deg)' : '' }}></img>
      </Text>
      {isSelectorShow && selector === 'status' && (
        <TypeSelector id="statusSelector">
          <TypeOption
            isSelected={!selected}
            key={-1}
            onClick={() => {
              handleOnStatusClick(undefined)
            }}
          >
            All
          </TypeOption>
          {options
            && options.map((status: OrderStatus, index: number) => (
              <TypeOption
                key={index}
                isSelected={selected === status}
                onClick={() => {
                  handleOnStatusClick(status)
                }}
              >
                {getStatusString(status)}
              </TypeOption>
            ))}
        </TypeSelector>
      )}
    </SelectorContainer>
  )
}
