import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'stores/auth/actions'
import { IAppState } from 'stores'
import { LogoutContainer, Section, AvatarContainer } from './style'

interface IProp {
  adminName?: string
}

export const User: FC<IProp> = () => {
  const adminName = useSelector((state: IAppState) => state.auth.userName)
  const config = useSelector((state: IAppState) => state.store.config)
  const dispatch = useDispatch()

  return (
    <Section>
      {config.jiraHelpdeskLink && (
        <LogoutContainer
          onClick={() => {
            window.open(config.jiraHelpdeskLink, '_blank')
          }}
        >
          Jira Helpdesk
        </LogoutContainer>
      )}

      <LogoutContainer
        onClick={() => {
          dispatch(logout())
        }}
      >
        Sign out
      </LogoutContainer>
      <AvatarContainer>
        {adminName?.charAt(0).toUpperCase()}
      </AvatarContainer>
    </Section>
  )
}
