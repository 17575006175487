import { all, fork } from 'redux-saga/effects'
import { adminSagas } from './admin/sagas'
import { auditsSagas } from './audits/sagas'

import { authSagas } from './auth/sagas'
import { orderSagas } from './orders/sagas'
import { storeSagas } from './store/sagas'

/* istanbul ignore next */
export function* rootSagas() {
  yield all([fork(authSagas)])
  yield all([fork(orderSagas)])
  yield all([fork(adminSagas)])
  yield all([fork(storeSagas)])
  yield all([fork(auditsSagas)])
}
