/* eslint-disable consistent-return */
import { put } from 'redux-saga/effects'
import { IResponse } from '../interface/response/success.response'
import { resetSubmitting } from './common/actions'

export function* checkResponse<T>(response?: IResponse<T>, nextAction?: Function, _failAction?: (msg: string) => void) {
  yield put(resetSubmitting())
  if (!response) return
  if (!!nextAction) {
    yield put(nextAction(response.data, response.pagination, response.totalCount))
  }
}

export function* checkResponseCB<T>(
  response?: IResponse<T>,
  nextAction?: (data: T) => void,
  _failAction?: (msg: string) => void,
) {
  if (!response) return
  if (!!nextAction) {
    yield nextAction(response.data)
  }
}
