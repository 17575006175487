import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux'
import { useDispatch as useReduxDispatch } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { toast } from 'react-toastify'
import { auditsReducer } from './audits/reducer';
import { authReducer } from './auth/reducer'
import { adminReducer } from './admin/reducer'
import { orderReducer } from './orders/reducer'
import { storeReducer } from './store/reducer'

export const sagaMiddleware = createSagaMiddleware({
  onError: () => {
    toast.error('Unknown Error.')
  },
})

const reducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  order: orderReducer,
  store: storeReducer,
  audits: auditsReducer,
})

// this variable will be set if you have redux-dev-tools extension installed in your browser
// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

/* istanbul ignore next */
export const configureStore = (preloadedState = {}) =>
  createStore(reducer, preloadedState, composeEnhancers(applyMiddleware(sagaMiddleware)))

export const store = configureStore()

export type IAppState = ReturnType<typeof reducer>

export type IDispatch = typeof store.dispatch

export const useDispatch = () => useReduxDispatch<IDispatch>()
