import React, {
  FC, useState, useEffect, useCallback,
} from 'react'
import { Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Logo from 'assets/icons/blue_logo.svg'

import { history } from '../../browserHistory'
import { IAppState } from '../../stores'
import {
  LoginButton,
  Title,
  NormalTitle,
  ColorTitle,
  InputBox,
  AccountInput,
  LoginPanel,
  Container,
  AccountLabel,
  Cover,
  Main,
} from './styled'
import { requestLogin, checkAuth } from '../../stores/auth/actions'

export const LoginPage: FC = () => {
  const dispatch = useDispatch()
  const accountRef = React.createRef<HTMLInputElement>()
  const passwordRef = React.createRef<HTMLInputElement>()
  const tokenExists = useSelector((state: IAppState) => !!state.auth?.token)
  const placeholder = {
    user: {
      default: 'Enter your username here',
      error: 'Enter username to sign in',
    },
    password: {
      default: 'Enter your password here',
      error: 'Enter password to sign in',
    },
  }
  enum ButtonStatus {
    INIT = 'init',
    ERR = 'error',
    SUCC = 'success',
  }
  const [isLogin] = useState(false)
  const [usernamePlaceholdr, setUsernamePlaceholdr] = useState(
    placeholder.user.default,
  )
  const [passwordPlaceholdr, setpasswordPlaceholdr] = useState(
    placeholder.password.default,
  )

  const [loginBtnStatus, setLoginBtnStatus] = useState(ButtonStatus.INIT)
  useEffect(() => {
    if (
      usernamePlaceholdr === placeholder.user.error
      || passwordPlaceholdr === placeholder.password.error
    ) {
      setLoginBtnStatus(ButtonStatus.ERR)
    }
  }, [
    ButtonStatus.ERR,
    placeholder.password.error,
    placeholder.user.error,
    usernamePlaceholdr,
    passwordPlaceholdr,
  ])

  useEffect(() => {
    dispatch(checkAuth())
  }, [dispatch])

  const handleChange = useCallback(() => {
    const username = accountRef.current?.value
    const password = passwordRef.current?.value
    if (username && password) {
      setLoginBtnStatus(ButtonStatus.SUCC)
    } else if ((username && !password) || (!username && password)) {
      setLoginBtnStatus(ButtonStatus.INIT)
    }
    setUsernamePlaceholdr(placeholder.user.default)
    setpasswordPlaceholdr(placeholder.password.default)
  }, [
    ButtonStatus.INIT,
    ButtonStatus.SUCC,
    placeholder.user.default,
    placeholder.password.default,
    accountRef,
    passwordRef,
  ])

  const onLogin = useCallback(() => {
    const username = accountRef.current?.value
    const password = passwordRef.current?.value
    if (!username) {
      accountRef?.current?.focus()
      setUsernamePlaceholdr(placeholder.user.error)
      setLoginBtnStatus(ButtonStatus.ERR)
      return
    }
    if (!password) {
      passwordRef?.current?.focus()
      setpasswordPlaceholdr(placeholder.password.error)
      setLoginBtnStatus(ButtonStatus.ERR)
      return
    }
    dispatch(requestLogin({ username, password }))
  }, [
    accountRef,
    passwordRef,
    dispatch,
    placeholder.password.error,
    placeholder.user.error,
    ButtonStatus.ERR,
  ])

  if (tokenExists) {
    const params = new URLSearchParams(window.location.search)
    const retUrl = params.get('returl')
    history.push(retUrl || '/retailer/orders')
    return <></>
  }

  const onEnterDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onLogin()
    }
  }

  return isLogin ? (
    <Redirect to="/"></Redirect>
  ) : (
    <Container>
      <Cover>
        <Main>
          <img
            src={Logo}
            alt=""
            style={{ marginBottom: '30px', width: '100px' }}
          />
          <LoginPanel
            data-test-id="111"
            onKeyDown={onEnterDown}
            right={30}
            top={0}
          >
            <Title>
              <NormalTitle>Welcome to your</NormalTitle>
              <ColorTitle>Store Management Portal</ColorTitle>
            </Title>
            <InputBox>
              <AccountLabel>Username</AccountLabel>
              <AccountInput
                isError={usernamePlaceholdr === placeholder.user.error}
                ref={accountRef}
                name="account"
                placeholder={usernamePlaceholdr}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <AccountLabel>Password</AccountLabel>
              <AccountInput
                isError={passwordPlaceholdr === placeholder.password.error}
                ref={passwordRef}
                type="password"
                name="password"
                placeholder={passwordPlaceholdr}
                onChange={handleChange}
              />
            </InputBox>
            <LoginButton status={loginBtnStatus} onClick={onLogin}>
              Sign in
            </LoginButton>
          </LoginPanel>
        </Main>
      </Cover>
    </Container>
  )
}
