import styled from 'styled-components'

export const Container = styled.div`
  height: 38px;
  margin: 12px 20px 0 0;
  color: #2e415b;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  background: #f4f6f7;
  border-radius: 19px;
`

export const AvatarContainer = styled(Container)`
  width: 38px;
`

export const LogoutContainer = styled(Container)`
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  background: transparent;
  border-radius: 0;
  cursor: pointer;
`
export const Section = styled.div`
  display: flex;
`
