import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { rootSagas } from 'stores/sagas'
import { ThemeProvider } from 'styled-components'
import { theme } from 'theme'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { sagaMiddleware, store } from './stores'

sagaMiddleware.run(rootSagas)

toast.configure({
  autoClose: 3000,
  draggable: false,
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
