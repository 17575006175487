import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IAppState } from 'stores'
import { ICase, ITarget } from 'stores/receipt/types'
import { CameraBar } from './cameraBar'
import { CaseItem } from './caseItem'
import { CasePlayer } from './casePlayer'
import {
  Container, VideoGroup, Cover, CaseFooter,
} from './styled'

interface IProps {
  currentCase?: ICase
  cases?: ICase[]
  onClose: () => void
}

export const CaseViewerComponent: FC<IProps> = ({ currentCase, onClose, cases }) => {
  const [currentCamera, setCurrentCamera] = useState<number>(0)
  const [playingCase, setPlayingCase] = useState(currentCase)
  const [currentTarget, setCurrentTarget] = useState<ITarget>()
  const [currentVideo, setCurrentVideo] = useState(currentCase?.videos?.[currentCamera])
  const targets = useSelector((state: IAppState) => state.order.targets)

  const onSelected = (item: ICase) => {
    setPlayingCase(item)
    if (item.videos?.length > 0) {
      setCurrentCamera(0)
    }
  }

  useEffect(() => {
    setCurrentVideo(playingCase?.videos[currentCamera])
    setCurrentTarget(targets?.filter((t) => t.targetId === playingCase?.targetId)[0])
  }, [playingCase, currentCamera, targets])
  return (
    <Container>
      <CameraBar
        camera={currentCamera}
        onSelectCamera={(i) => {
          setCurrentCamera(i)
        }}
        onClose={onClose}
        videos={playingCase?.videos || []}
      />
      <CasePlayer video={currentVideo?.video || ''} />
      <CaseFooter>
        <Cover src={currentTarget && currentTarget.imageUrl} alt="" height="120" width="120"></Cover>
        <VideoGroup>
          {cases?.map((item) => (
            <CaseItem
              currentCase={item}
              selected={playingCase === item}
              data={item.videos[0]}
              onSelected={() => onSelected(item)}
            />
          ))}
        </VideoGroup>
      </CaseFooter>
    </Container>
  )
}

export const CaseViewer = CaseViewerComponent
