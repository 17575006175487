import dayjs from 'dayjs'
import React, { FC } from 'react'
import { ICase, IVideo } from 'stores/receipt/types'
import { CaseItemContainer, CaseTime, Point } from './styled'

interface IProps {
  data: IVideo
  currentCase: ICase
  selected?: boolean
  hasProducts?: boolean
  onSelected?: VoidFunction
}

export const CaseItem: FC<IProps> = ({
  data, selected, onSelected, currentCase,
}) => (
  <CaseItemContainer name="caseItem" onClick={onSelected} selected={selected}>
    <CaseTime selected={selected}>
      {currentCase.operationResult?.carts && currentCase.operationResult?.carts[0]?.items.length > 0 && <Point></Point>}
      {dayjs(data.timestamp * 1000).format('mm:ss')}
    </CaseTime>
    <div></div>
  </CaseItemContainer>
)
