import { toast } from 'react-toastify'
import { fetchAudit, FETCH_AUDIT, setAudit } from 'stores/audits/actions'
import {
  call, put, takeLatest, select,
} from 'redux-saga/effects'
import AuditsAPI from 'api/audits'
import * as R from 'ramda'
import { selectCurrentOrderAudit } from './selectors'
import { IAudit, IOrderAuditsResponse } from '../../interface/audits/audit'

import {
  FETCH_ORDER_AUDITS,
  fetchOrderAudits,
  createAudit,
  CREATE_AUDIT,
  setAudits,
  requestUpdateAudit,
  REQUEST_UPDATE_AUDIT,
} from './actions'

export function* fetchOrderAuditsSaga(action: ReturnType<typeof fetchOrderAudits>) {
  try {
    const response: IOrderAuditsResponse = yield call(AuditsAPI.getOrderAudits, action.payload.orderId)
    const groupedAudits = R.groupBy((audit) => R.toString(audit.orderId), response.audits)
    yield put(setAudits(groupedAudits))

    const currentAudit: IAudit = yield select(selectCurrentOrderAudit)

    if (!R.isNil(currentAudit)) {
      yield put(fetchAudit(currentAudit.id))
    }
  } catch (error) {
    toast.error(error.message)
  }
}

export function* fetchAuditSaga(action: ReturnType<typeof fetchAudit>) {
  try {
    const response: IAudit = yield call(AuditsAPI.getAudit, action.payload.auditId)
    yield put(setAudit(response.orderId, response))
  } catch (error) {
    toast.error(error.message)
  }
}

export function* createAuditSaga(action: ReturnType<typeof createAudit>) {
  try {
    const response: IAudit = yield call(AuditsAPI.createAudit, action.payload.orderId)
    yield put(setAudits({ [action.payload.orderId]: [response] }))
  } catch (error) {
    toast.error(error.message)
  }
}

export function* requestUpdateAuditSaga(action: ReturnType<typeof requestUpdateAudit>) {
  try {
    const response: IAudit = yield call(AuditsAPI.updateAudit, action.payload.auditId, action.payload.audit)
    yield put(setAudits({ [response.orderId]: [response] }))
  } catch (error) {
    toast.error(error.message)
  }
}

export function* auditsSagas() {
  yield takeLatest(FETCH_ORDER_AUDITS, fetchOrderAuditsSaga)
  yield takeLatest(FETCH_AUDIT, fetchAuditSaga)
  yield takeLatest(CREATE_AUDIT, createAuditSaga)
  yield takeLatest(REQUEST_UPDATE_AUDIT, requestUpdateAuditSaga)
}
