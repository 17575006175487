import * as actions from './actions'
import { IStoresState } from './types'

export const initialState: IStoresState = {
  config: {
    jiraHelpdeskLink: '',
    currency: {
      code: 'USD',
      symbol: '$',
    },
  },
}

export const storeReducer = (state: IStoresState = initialState, action: actions.IStoreActions) => {
  switch (action.type) {
    case actions.REQUEST_GET_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.payload.config,
      }
    default:
      return state
  }
}
