import API from 'api/API'
import { LoginPage } from 'pages/login'
import { OrderDetailPage } from 'pages/orderDetail'
import React from 'react'
import {
  BrowserRouter, Redirect, Route, Router, Switch,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'stores'
import { requestConfig } from 'stores/store/actions'
import './App.css'
import { history } from './browserHistory'
import { Page404 } from './pages/404/404'
import { Index } from './pages/index'
import * as authActions from './stores/auth/actions'

function App() {
  const dispatch = useDispatch()
  if (API.getAuthToken()) dispatch(requestConfig())

  return (
    <BrowserRouter>
      <ToastContainer></ToastContainer>
      <Router history={history}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>
          <Route path="/login" exact>
            <LoginPage></LoginPage>
          </Route>
          <Route path="/retailer/orders" exact component={Index}></Route>
          <Route path="/retailer/orders/:id" exact component={OrderDetailPage}></Route>
          <Route path="/logout" exact>
            {() => {
              dispatch(authActions.logout())
            }}
          </Route>
          <Route path="*" exact component={Page404}></Route>
        </Switch>
      </Router>
    </BrowserRouter>
  )
}

export default App
