import styled from 'styled-components'
import is from 'styled-is'
import { theme } from 'styled-tools'

export const SelectorContainer = styled.div`
  position: relative;
  width: 111px;
  height: 36px;
  text-align: center;
  border: 1px solid ${theme('colors.grey.300')};
  border-radius: 0.5rem;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
`
export const Text = styled.div`
  width: 100%;
  height: 36px;
  color: #2e415b;
  font-size: 14px;
  line-height: 36px;
`

export const TypeSelector = styled.ul`
  position: absolute;
  left: 50%;
  box-sizing: border-box;
  width: 180px;
  max-height: 200px;
  margin-top: 10px;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fdfdfd;
  border: 1px solid #d8e6ed;
  border-radius: 25px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transform: translate3d(-50%, 0, 0);
`

export const TypeOption = styled.li<{ isSelected?: boolean }>`
  display: block;
  padding: 6px 20px;
  color: #2e415b;
  font-size: 16px;
  text-align: left;
  list-style-type: none;
  background: #fff;
  border: none;
  &:hover {
    background: #9ed9f6;
  }
  ${is('isSelected')`
    background: #9ed9f6;
  `}
`
