import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IAppState, useDispatch } from 'stores'
import { requestOrderCases, requestOrderInfo, requestOrderContest } from 'stores/orders/actions'
import { useSelector } from 'react-redux'
import { OrderStatus } from 'stores/orders/types'
import { ICase } from 'stores/receipt/types'
import { selectHasUserDebugRole } from 'stores/auth/selectors'
import { selectContestData, selectFinalOrder } from 'stores/orders/selectors'
import {
  Flex, GeneralInfo, PlayerWrap, RightPanel,
} from './style'

import { General } from './components/general'
import { Detail } from './components/detail'
import { Cases } from './components/cases'
import { ContestProduct } from './components/contestProduct'
import { CaseViewer } from './components/caseViewer'

export const OrderDetail: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [playCase, setPlayCase] = useState<ICase>()
  const [isInAuditMode, setIsInAuditMode] = useState<boolean>(false)

  const currentOrder = useSelector((state: IAppState) => state.order?.currentOrder)
  const finalOrder = useSelector(selectFinalOrder)
  const targets = useSelector((state: IAppState) => state.order?.targets)
  const cases = useSelector((state: IAppState) => state.order?.cases)
  const contestProduct = useSelector(selectContestData)
  const hasDebugRole = useSelector(selectHasUserDebugRole)

  const isContested = currentOrder?.status === OrderStatus.CONTESTED
  const isReviewed = currentOrder?.status === OrderStatus.REVIEWED

  useEffect(() => {
    const pathname = location.pathname.split('/')
    const orderId = pathname[pathname.length - 1]
    dispatch(requestOrderInfo(orderId))
  }, [location, dispatch])

  useEffect(() => {
    if ((isContested || isReviewed) && currentOrder) {
      dispatch(requestOrderContest(currentOrder.id))
    }
  }, [currentOrder, dispatch, isContested, isReviewed])

  useEffect(() => {
    currentOrder?.sessionId && dispatch(requestOrderCases(currentOrder?.sessionId))
  }, [currentOrder, dispatch])

  const showCases = isContested || isInAuditMode || hasDebugRole
  return currentOrder ? (
    <Flex>
      {playCase ? (
        <PlayerWrap>
          <CaseViewer onClose={() => setPlayCase(undefined)} currentCase={playCase} cases={cases} />
        </PlayerWrap>
      ) : (
        <RightPanel>
          <GeneralInfo>
            {currentOrder && (
              <General
                entranceTime={targets?.[0]?.entryTimestamp}
                exitTime={targets?.[targets.length - 1]?.exitTimestamp}
                order={currentOrder}
                paymentId={contestProduct?.transaction?.paymentTransactionId}
              />
            )}
            {currentOrder && finalOrder && (isContested || isReviewed) && (
              <ContestProduct data={contestProduct} currentOrder={currentOrder} finalOrder={finalOrder} />
            )}
            {showCases && <Cases data={cases} onSelected={setPlayCase} sessionId={currentOrder.sessionId!} />}
          </GeneralInfo>
        </RightPanel>
      )}
      {currentOrder && finalOrder && (
        <Detail
          currentOrder={currentOrder}
          finalOrder={finalOrder}
          isInAuditMode={isInAuditMode}
          setIsInAuditMode={setIsInAuditMode}
        />
      )}
    </Flex>
  ) : (
    <div></div>
  )
}
