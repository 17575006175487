import { CopyIcon } from 'components/icons/copy'
import { SubTitle, SubTitleContainer } from 'components/orderDetail/style'
import { getStatusString } from 'components/orderList/utils'
import dayjs from 'dayjs'
import copy from 'copy-to-clipboard'
import React, { FC, useCallback } from 'react'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'

import { OrderStatus, StatusColor, IOrder } from 'stores/orders/types'
import { BackButton } from 'components/common/BackButton'
import { DateText, Tag, TimeText } from '../../../orderList/style'
import {
  GeneralRow, BorderBox, GeneralCell, StatusCell, GeneralRowHeader, GeneralHeaderCell,
} from './style'
import { Container } from '../contestProduct/style'

interface IProps {
  order: IOrder
  entranceTime?: number
  exitTime?: number
  paymentId?: string
}

export const General: FC<IProps> = ({
  order, entranceTime, exitTime, paymentId,
}) => {
  const copyPaymentId = useCallback(() => {
    if (paymentId) {
      copy(paymentId)
        && toast.success('The payment ID have been copied.', {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: false,
          progress: 0,
        })
    }
  }, [paymentId])
  return (
    <Container>
      <ReactTooltip />
      <SubTitleContainer>
        <BackButton />
        <SubTitle>Order Details</SubTitle>
      </SubTitleContainer>
      <BorderBox>
        <GeneralRowHeader>
          <GeneralHeaderCell isTh>Receipt ID</GeneralHeaderCell>
          <GeneralHeaderCell>Payment ID</GeneralHeaderCell>
          <GeneralHeaderCell isTh>Entrance</GeneralHeaderCell>
          <GeneralHeaderCell isTh>Exit</GeneralHeaderCell>
          <StatusCell isTh>Status</StatusCell>
        </GeneralRowHeader>
        <GeneralRow>
          <GeneralCell>
            {order.id}
          </GeneralCell>
          <GeneralCell>
            {paymentId ? (
              <CopyIcon
                data-tip="Click to copy payment ID"
                onClick={copyPaymentId}
                title={`${paymentId.substr(0, 6)}••••••`}
              >
              </CopyIcon>
            ) : (
              '-'
            )}
          </GeneralCell>
          <GeneralCell>
            <DateText>
              {dayjs(Number(entranceTime) * 1000).format('MMM D, YYYY')}
            </DateText>
            <TimeText>
              {dayjs(Number(entranceTime) * 1000).format('h:mm:ss A')}
            </TimeText>
          </GeneralCell>
          <GeneralCell>
            <DateText>
              {dayjs(Number(exitTime) * 1000).format('MMM D, YYYY')}
            </DateText>
            <TimeText>
              {dayjs(Number(exitTime) * 1000).format('h:mm:ss A')}
            </TimeText>
          </GeneralCell>
          <GeneralCell>
            <Tag color={StatusColor[order.status as unknown as OrderStatus]}>
              {getStatusString(order.status)}
            </Tag>
          </GeneralCell>
        </GeneralRow>
      </BorderBox>
    </Container>
  )
}
