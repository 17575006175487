import styled from 'styled-components'
import AiFiLogo from 'assets/icons/Logo.svg'
import is, { isNot } from 'styled-is'
import { theme } from 'theme'

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`
export const Logo = styled.div`
  width: 64px;
  height: 40px;
  margin: 20px 0 40px 0;
  background: url('${AiFiLogo}') transparent no-repeat center center/contain;
`
export const MenuItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 88px;
  border-radius: 1000px;
  ${is('isSelected')`
  background: #fff;
 `}
  ${isNot('isSelected')`
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
 `}
`
export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${theme.dims.leftBar.width};
  min-width: ${theme.dims.leftBar.width};
  min-height: 100vh;
  color: ${theme.colors.deepBlue[200]};
  font-size: 14px;
  line-height: 16px;
  background: ${theme.colors.deepBlue[200]};
  border-radius: 0 0 50px 0;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: ${theme.dims.topBar.height};
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  background: ${theme.colors.deepBlue[200]};
`
export const Content = styled.div`
  height: calc(100vh - ${theme.dims.topBar.height});
  padding: 24px 24px 0 24px;
  background: #d5dce6;
  border-radius: 32px 0px 0px 0px;
`
export const Title = styled.div`
  padding: 20px 13px;
  color: #2e415b;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
`
export const SubMenuItem = styled.div<{ isSelected?: boolean }>`
  margin-right: 20px;
  ${is('isSelected')`
 color: #fff;
 `}
`
export const SubMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  padding-left: 64px;
`

export const MenuLink = styled.a<{ isCurrent?: boolean }>`
  display: flex;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-weight: 400;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  ${is('isCurrent')`
    color: rgba(255, 255, 255, 1);
  `}
`

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  vertical-align: middle;
`
