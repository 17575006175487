import { IPagination } from 'interface/retailer/common'
import { ITarget, IOrderContest } from 'stores/receipt/types'
import { CreateOrderContestPayload, IProduct, OrderStatus } from 'stores/orders/types'
import { IOrder } from './types'
import { ICase } from '../receipt/types'

export const REQUEST_ORDERS = 'order/request' as const

export const requestOrders = (
  id?: number,
  startTime?: Date,
  endTime?: Date,
  page?: number,
  status?: OrderStatus,
  storeId?: string,
  pageSize?: number,
) => ({
  type: REQUEST_ORDERS,
  payload: {
    id,
    startTime,
    endTime,
    page,
    status,
    storeId,
    pageSize,
  },
})

export const REQUEST_ORDERS_SUCCESS = 'order/request_success' as const

export const requestOrdersSuccess = (data: IOrder[], pagination: IPagination) => ({
  type: REQUEST_ORDERS_SUCCESS,
  payload: { data, pagination },
})

export const SELECT_ORDERS_PAGE = 'order/select_orders_page' as const
export const selectOrdersPage = (page: number, size: number) => ({
  type: SELECT_ORDERS_PAGE,
  payload: {
    page,
    size,
  },
})

export const SET_CURRENT_ORDER = 'order/select_current_order' as const

export const setCurrentOrder = (order?: IOrder) => ({
  type: SET_CURRENT_ORDER,
  payload: { order },
})

export const SET_FINAL_ORDER = 'order/set_final_order' as const

export const setFinalOrder = (order?: IOrder) => ({
  type: SET_FINAL_ORDER,
  payload: { order },
})

// order detail
export const REQUEST_ORDER_INFO = 'order/request_info' as const

export const requestOrderInfo = (id: string) => ({
  type: REQUEST_ORDER_INFO,
  payload: {
    id,
  },
})

export const REQUEST_ORDER_CASES = 'order/request_order_cases' as const

export const requestOrderCases = (id: string) => ({
  type: REQUEST_ORDER_CASES,
  payload: {
    id,
  },
})

export const REQUEST_ORDER_CASES_SUCCESS = 'order/request_order_cases_success' as const

export const requestOrderCasesSuccess = (data: { targets: ITarget[], cases: ICase[] }) => ({
  type: REQUEST_ORDER_CASES_SUCCESS,
  payload: {
    data,
  },
})
// PRODUCT
export const REQUEST_RETAILER_PRODUCTS = 'order/request_product' as const

export const requestRetailerProducts = (name?: string, time?: number, page?: number, pageSize?: number) => ({
  type: REQUEST_RETAILER_PRODUCTS,
  payload: {
    name,
    time,
    page,
    pageSize,
  },
})

export const REQUEST_RETAILER_PRODUCTS_SUCCESS = 'order/request_retailer_products_success' as const

export const requestRetailerProductsSuccess = (data: IProduct[], pagination: IPagination, totalCount: number) => ({
  type: REQUEST_RETAILER_PRODUCTS_SUCCESS,
  payload: { data, totalCount },
})

export const SWITCH_CONTEST_PRODUCT_SELECTOR_VISIBLE = 'order/switch_contest_product_selector_visible' as const

export const switchContestProductSelectorVisible = (visible?: boolean) => ({
  type: SWITCH_CONTEST_PRODUCT_SELECTOR_VISIBLE,
  payload: {
    visible,
  },
})

export const SELECT_CONTEST_PRODUCT_PAGE = 'order/select_contest_product_page' as const

export const selectContestProductPage = (page: number, size: number) => ({
  type: SELECT_CONTEST_PRODUCT_PAGE,
  payload: {
    page,
    size,
  },
})

export const REQUEST_SUBMIT_CONTESTED_ORDER = 'order/submit_contested_order' as const

export const requstSubmitContestedOrder = (
  reviewedTotalPrice: number,
) => ({
  type: REQUEST_SUBMIT_CONTESTED_ORDER,
  payload: {
    reviewedTotalPrice,
  },
})

export const RESET_ORDERS = 'order/reset_orders' as const
export const resetOrders = () => ({
  type: RESET_ORDERS,
  payload: {},
})

export const CONTEST_ORDER = 'order/contest_order' as const
export const contestOrder = (contest: CreateOrderContestPayload) => ({
  type: CONTEST_ORDER,
  payload: {
    contest,
  },
})

export const SET_CURRENT_ORDER_AS_CONTESTED = 'order/set_current_order_as_contested' as const
export const setCurrentOrderAsContested = () => ({
  type: SET_CURRENT_ORDER_AS_CONTESTED,
  payload: {},
})

export const REQUEST_ORDER_CONTEST = 'order/request_order_contest' as const;
export const requestOrderContest = (orderId: number) => ({
  type: REQUEST_ORDER_CONTEST,
  payload: { orderId },
})

export const SET_CONTEST_PRODUCT = 'order/set_contest_product' as const;
export const setContestProduct = (contestProduct: IOrderContest) => ({
  type: SET_CONTEST_PRODUCT,
  payload: {
    contestProduct,
  },
})

export type IOrderActions = ReturnType<
  | typeof requestOrders
  | typeof requestOrdersSuccess
  | typeof selectOrdersPage
  | typeof setCurrentOrder
  | typeof requestOrderInfo
  | typeof requestOrderCases
  | typeof requestOrderCasesSuccess
  | typeof requestRetailerProducts
  | typeof requestRetailerProductsSuccess
  | typeof switchContestProductSelectorVisible
  | typeof selectContestProductPage
  | typeof setFinalOrder
  | typeof requstSubmitContestedOrder
  | typeof resetOrders
  | typeof contestOrder
  | typeof setCurrentOrderAsContested
  | typeof requestOrderContest
  | typeof setContestProduct
  >
