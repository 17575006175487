import { call, put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { requestConfig } from 'stores/store/actions'
import API from '../../api/API'
import {
  REQUEST_LOGIN, requestLoginSuccess, requestLogin, REQUEST_LOGOUT,
} from './actions'
import AuthAPI from '../../api/auth'
import { IAuthResponse } from '../../interface/response/auth.response'

import { history } from '../../browserHistory'

export function* sagaRequestLogin(action: ReturnType<typeof requestLogin>) {
  try {
    const response: IAuthResponse = yield call(AuthAPI.authorize, action.payload.authData)
    API.setAuthToken(response.token, response.username, dayjs(response.expiresAt).unix() * 1000 - Date.now())
    yield put(requestLoginSuccess(response.token, response.username, dayjs(response.expiresAt).toDate()))
    yield put(requestConfig())
  } catch (e) {
    toast.error(e.message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    })
  }
}

export function* sagaRequestLogout() {
  yield call(AuthAPI.logout)
  API.clearAuthToken()
  yield history.push('/login')
}

export function* authSagas() {
  yield takeEvery(REQUEST_LOGIN, sagaRequestLogin)
  yield takeEvery(REQUEST_LOGOUT, sagaRequestLogout)
}
