import React, { FC, useEffect, useState } from 'react'
import IconCheck from 'assets/icons/ic-check.svg'
import {
  IOrder, IProductSnapshot, OrderStatus,
} from 'stores/orders/types'
import { IOrderContestProduct } from 'stores/receipt/types'
import { useDispatch } from 'react-redux'
import { setFinalOrder } from 'stores/orders/actions'
import ProductAPI from 'api/product'
import { toast } from 'react-toastify'
import {
  Container, Thumbnail, Name, CurrentItem, OldItem, ItemInfo, CheckBox, CheckBoxImg, CheckBoxWrap, Label,
} from './style'
import { BaseInfo } from '../product/style'
import { LABELS } from './types'

export const getLabel = (originalQuantity = 0, contestQuantity = 0) => {
  if (originalQuantity === contestQuantity) {
    return LABELS.NO_CHANGE
  }
  if (originalQuantity > 0 && contestQuantity > 0) {
    return LABELS.QUANTITY
  }
  if (originalQuantity === 0) {
    return LABELS.ADDED
  }
  if (contestQuantity === 0) {
    return LABELS.REMOVED
  }
  return LABELS.NO_CHANGE
}

export const getDiff = (originalQuantity = 0, contestQuantity = 0) => {
  if (contestQuantity === originalQuantity) {
    return ''
  }
  const diff = contestQuantity - originalQuantity
  if (diff < 0) {
    return ` ${diff}`
  }
  return ` +${diff}`
}

export const findProduct = (products: IProductSnapshot[] | undefined, productId: number | string) =>
    products?.find((product) => String(product.productId) === String(productId))

export const isApplied = (productId: number | string, quantity: number, finalOrder: IOrder): boolean => {
  const product = findProduct(finalOrder.products, productId)
  if (product?.quantity === quantity) {
    return true
  }
  return false
}
interface IProps {
  product: IOrderContestProduct
  currentOrder: IOrder
  finalOrder: IOrder
}

export const ProductSelector: FC<IProps> = ({ product, currentOrder, finalOrder }) => {
  const [selected, setSelected] = useState<boolean>(false)
  const originalProduct = findProduct(currentOrder.products, product.productId)
  const label = getLabel(originalProduct?.quantity, product.contestedQuantity)
  const diff = getDiff(originalProduct?.quantity, product.contestedQuantity)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelected(isApplied(product.productId, product.contestedQuantity, finalOrder))
  }, [finalOrder, product])

  const toggle = async () => {
    if (currentOrder.status === OrderStatus.REVIEWED) {
      return
    }
    setSelected(!selected)

    const originalProduct = findProduct(currentOrder.products, product.productId)
    const products: IProductSnapshot[] = []

    if (!originalProduct && !selected) {
      try {
        const data = await ProductAPI.getProductDetail(product.productId)

        const p = {
          barcode: data.barcode,
          id: data.id,
          name: product.name,
          price: data.price,
          productId: product.productId,
          quantity: product.contestedQuantity,
          thumbnail: product.thumbnail,
        }
        products.push(p)
      } catch (error) {
        toast.error(error.message)
      }
    }

    for (const p of finalOrder.products || []) {
      if (String(p.productId) !== String(product.productId)) {
        products.push(p)
        continue
      }

      if (!originalProduct) {
        continue
      }

      if (!selected) {
        products.push({ ...p, quantity: product.contestedQuantity })
      } else {
        products.push({ ...p, quantity: originalProduct.quantity })
      }
    }

    const subtotalPrice = products.reduce((sum, e) => sum + Number(e.quantity) * Number(e.price), 0)
    dispatch(
      setFinalOrder({
        ...finalOrder,
        products,
        subtotalPrice: subtotalPrice.toFixed(2).toString(),
        totalPrice: subtotalPrice.toFixed(2).toString(),
      }),
    )
  }
  return label === LABELS.NO_CHANGE ? (
    <div></div>
  ) : (
    <Container>
      <Thumbnail thumbnail={product.thumbnail}></Thumbnail>
      <BaseInfo>
        <Label label={label}>
          {label}
          {label === LABELS.QUANTITY && diff}
        </Label>
        <Name title={product.name}>
          {product.name}
        </Name>
      </BaseInfo>
      <ItemInfo>
        <CurrentItem>
          Items:
          {product.contestedQuantity}
        </CurrentItem>
        <OldItem>
          Items:
          {originalProduct?.quantity || 0}
        </OldItem>
      </ItemInfo>
      <CheckBoxWrap>
        <CheckBox
          isContested={currentOrder.status === OrderStatus.CONTESTED}
          selected={selected}
          onClick={() => {
            toggle()
          }}
        >
          <CheckBoxImg src={IconCheck} alt="" />
        </CheckBox>
      </CheckBoxWrap>
    </Container>
  )
}
