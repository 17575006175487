import { createSelector } from 'reselect'
import * as R from 'ramda'
import { sum } from 'lodash'
import { IGroupedAudits } from '../../interface/audits/audit'
import { IAppState } from '../index'

const isEmpty = R.anyPass([R.isNil, R.isEmpty])

export const selectAuditsState = (store: IAppState): IGroupedAudits => store.audits

export const selectCurrentOrderState = (store: IAppState) => store.order.currentOrder

export const selectCurrentOrderId = createSelector(selectCurrentOrderState, (currentOrder) => R.toString(currentOrder?.id))

export const selectCurrentOrderAudits = createSelector(
  selectAuditsState,
  selectCurrentOrderId,
  (audits, orderId) => R.prop(orderId, audits) ?? [],
)

export const selectCurrentOrderAudit = createSelector(selectCurrentOrderAudits, (audits) => R.nth(0, audits))

export const selectIsCurrentOrderAuditEmpty = createSelector(
  selectCurrentOrderAudit,
  (audit) => R.isNil(audit) || R.isEmpty(audit) || R.all(isEmpty, [audit.comment, audit.results]),
)

export const selectAuditedProducts = createSelector(selectCurrentOrderState, selectCurrentOrderAudit, (currentOrder, audit) => {
  const originalProducts = (currentOrder?.products ?? []).sort(
    (product1, product2) => Number(product1.productId!) - Number(product2.productId!),
  )
  const auditProducts = audit?.results ?? []
  return R.reduce(
    (finalProducts, { product, quantity, categories }) => {
      const targetProductIndex = R.findIndex((finalProduct) => finalProduct.productId === R.toString(product.id), finalProducts)

      if (targetProductIndex >= 0) {
        const auditedProduct = R.mergeRight(finalProducts[targetProductIndex], {
          ...product,
          productId: product.id,
          quantity,
          price: Number(product.price),
          categories,
        })

        return R.set(R.lensIndex(targetProductIndex), auditedProduct, finalProducts)
      }
      return R.append(
        {
          ...product,
          id: R.toString(product.id),
          productId: R.toString(product.id),
          quantity,
          price: product.price,
          categories,
        },
        finalProducts,
      )
    },
    originalProducts,
    auditProducts,
  )
})

export const selectAuditedProductsTotalPrice = createSelector(
  selectAuditedProducts,
  (products) => sum(products.map(({ quantity, price }) => Number(quantity) * Number(price))),
)

export const selectAuditedProductsTotalQuantity = createSelector(
  selectAuditedProducts,
  (products) => sum(products.map(({ quantity }) => Number(quantity))),
)