import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Header } from 'components/typography/Header'
import { BottomBox } from './style'
import { StyledTextarea } from './StyledTextarea'

interface IProps {
  comment: string
}

export const AuditBoxReadOnly: FC<IProps> = ({ comment }) => {
  const theme = useTheme()
  return (
    <BottomBox background={theme.colors.grey[200]}>
      <Header as="h4" color="primary">
        Audition Comments
      </Header>
      <StyledTextarea disabled value={comment ?? ''}></StyledTextarea>
    </BottomBox>
  )
}
