import { IGroupedAudits } from 'interface/audits/audit'
import * as R from 'ramda'
import * as actions from './actions'

export const initialState: IGroupedAudits = {}

export const auditsReducer = (state: IGroupedAudits = initialState, action: actions.IAuditsActions) => {
  switch (action.type) {
    case actions.SET_AUDITS:
      return R.mergeDeepRight(state, action.payload.audits) as IGroupedAudits
    case actions.SET_AUDIT:
      return { ...state, [action.payload.orderId]: [action.payload.audit] }
    default:
      return state
  }
}
