import React, { FC } from 'react'
import { Button } from 'components/button'
import { EditIcon } from 'components/icons/edit'
import { theme } from 'theme'

interface IProps {
  isAuditEmpty: boolean
  isInAuditMode: boolean
  setIsInAuditMode: (auditMode: boolean) => void
  onAudit: () => void
}

export const AuditButton: FC<IProps> = ({
  isAuditEmpty, isInAuditMode, setIsInAuditMode, onAudit,
}) =>
  (isAuditEmpty ? (
    <Button disabled={isInAuditMode} onClick={onAudit} kind="primary" iconLeft={<EditIcon fillColor={theme.colors.white} />}>
      Audit
    </Button>
  ) : (
    <Button
      onClick={() => {
        setIsInAuditMode(!isInAuditMode)
      }}
      kind="primary"
    >
      {isInAuditMode ? 'Hide Audition' : 'View Audition'}
    </Button>
  ))
