import { toast } from 'react-toastify'
/* eslint-disable require-yield */
import {
  takeLatest, call, put, select,
} from 'redux-saga/effects'
import { IPagination } from 'interface/retailer/common'
import {
  prop, concat, uniqBy,
} from 'ramda'
import { CreateOrderContestPayload, IProduct, OrderStatus } from 'stores/orders/types'
import { IOrderContest } from 'stores/receipt/types'
import { checkResponse } from '../sagaUtils'
import { IResponse, IPagedResponse } from '../../interface/response/success.response'

import {
  requestOrders,
  requestOrdersSuccess,
  REQUEST_ORDERS,
  requestOrderInfo,
  REQUEST_ORDER_INFO,
  requestOrderCases,
  requestOrderCasesSuccess,
  REQUEST_ORDER_CASES,
  requestRetailerProductsSuccess,
  requestRetailerProducts,
  REQUEST_RETAILER_PRODUCTS,
  setCurrentOrder,
  setFinalOrder,
  requstSubmitContestedOrder,
  REQUEST_SUBMIT_CONTESTED_ORDER,
  CONTEST_ORDER,
  contestOrder,
  setCurrentOrderAsContested,
  REQUEST_ORDER_CONTEST,
  requestOrderContest,
  setContestProduct,
} from './actions'
import { IOrder } from './types'
import OrderAPI from '../../api/order'
import {
  selectCurrentOrder, selectFinalOrder, selectContestData, selectContestedOrder,
} from './selectors'

export function* sagaRequestOrders(action: ReturnType<typeof requestOrders>) {
  const req = action.payload
  try {
    const response: IPagedResponse<{ data: IOrder[], pagination: IPagination }> = yield call(OrderAPI.getOrderList, req)
    yield checkResponse(response, requestOrdersSuccess)
  } catch (error) {
    toast.error(error.message)
  }
}

export function* sagaRequestOrderInfo(action: ReturnType<typeof requestOrderInfo>) {
  try {
    const response: IResponse<IOrder> = yield call(OrderAPI.getOrder, action.payload.id)
    yield checkResponse(response, setCurrentOrder)
    yield checkResponse(response, setFinalOrder)
  } catch (error) {
    toast.error(error.message)
  }
}

export function* sagaRequestOrderCases(action: ReturnType<typeof requestOrderCases>) {
  try {
    const response: IResponse = yield OrderAPI.getCases(action.payload.id)
    yield checkResponse(response, requestOrderCasesSuccess)
  } catch (error) {
    toast.error(error.message)
  }
}

export function* sagaRequestRetailerProducts(action: ReturnType<typeof requestRetailerProducts>) {
  try {
    const response: IResponse<IProduct[]> = yield call(
      OrderAPI.getRetailerProducts,
      action.payload.name,
      action.payload.time,
      action.payload.page,
      action.payload.pageSize,
    )
    yield checkResponse(response, requestRetailerProductsSuccess)
  } catch (error) {
    toast.error(error.message)
  }
}

export function* sagaRequestContestedOrder(action: ReturnType<typeof requstSubmitContestedOrder>) {
  try {
    const finalOrder: IOrder = yield select(selectFinalOrder)
    const contestOrder: IOrderContest = yield select(selectContestData)

    const items = concat(
      finalOrder?.products?.map((finalItem) => ({
        productId: Number(finalItem.productId),
        reviewedQuantity: finalItem.quantity,
      })) || [],
      contestOrder?.items.map((item) => ({
        productId: Number(item.productId),
        reviewedQuantity: item.originalQuantity,
      })) || [],
    )
    const submitItems = uniqBy(prop('productId'), items)

    const response: IResponse<undefined> = yield OrderAPI.submitContestedOrder(
      finalOrder.id,
      submitItems,
      action.payload.reviewedTotalPrice,
    )
    yield checkResponse(response)
    window.location.reload()
  } catch (error) {
    toast.error(error.message)
  }
}

export function* sagaCreateOrderContest(action: ReturnType<typeof contestOrder>) {
  try {
    const newContestResponse: IResponse<CreateOrderContestPayload> = yield call(
      OrderAPI.createOrderContest,
      action.payload.contest,
    )

    yield checkResponse(newContestResponse, setCurrentOrderAsContested)
  } catch (error) {
    toast.error(error.message)
  }
}
export function* sagaRequestOrderContest(action: ReturnType<typeof requestOrderContest>) {
  const response: IOrderContest = yield OrderAPI.getOrderContest(action.payload.orderId)

  yield put(setContestProduct(response))

  const contestedOrder: ReturnType<typeof selectContestedOrder> = yield select(selectContestedOrder)
  const currentOrder: ReturnType<typeof selectCurrentOrder> = yield select(selectCurrentOrder)

  if (currentOrder?.status === OrderStatus.REVIEWED) {
    yield put(
      setFinalOrder({
        ...contestedOrder,
      }),
    )
  }
}

export function* orderSagas() {
  yield takeLatest(REQUEST_ORDERS, sagaRequestOrders)
  yield takeLatest(REQUEST_ORDER_INFO, sagaRequestOrderInfo)
  yield takeLatest(REQUEST_ORDER_CASES, sagaRequestOrderCases)
  yield takeLatest(REQUEST_RETAILER_PRODUCTS, sagaRequestRetailerProducts)
  yield takeLatest(REQUEST_SUBMIT_CONTESTED_ORDER, sagaRequestContestedOrder)
  yield takeLatest(CONTEST_ORDER, sagaCreateOrderContest)
  yield takeLatest(REQUEST_ORDER_CONTEST, sagaRequestOrderContest)
}
