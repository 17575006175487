import React, {
  FC, useCallback, useState, useRef,
} from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import * as R from 'ramda'
import { useTheme } from 'styled-components'
import { ChevronIcon } from 'components/icons/chevronIcon'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { setFinalOrder } from 'stores/orders/actions'
import { IOrder, IProductSnapshot } from 'stores/orders/types'
import CloseSvg from 'assets/icons/close-icon.svg'
import { AuditCategory } from 'interface/audits/audit'
import { findProduct, getDiff, getLabel } from '../productSelector'
import {
  CurrentItem, PriceWrap, OldItem, CloseIcon, Label, IssueLabel, IssueText,
} from '../productSelector/style'

import {
  Container,
  Quantity,
  BaseInfo,
  Thumbnail,
  Add,
  Sub,
  UnitPrice,
  Info,
  Name,
  QuantityInfo,
  NewQuantityContainer,
  LabelsWrap,
} from './style'
import { LABELS } from '../productSelector/types'
import { PreviousQuantity } from './PreviousQuantity'
import { DropdownContainer } from '../detail/components/DropdownContainer'
import { CheckboxContainer } from '../detail/components/CheckboxContainer'
import { DropdownWrapper } from '../detail/components/DropdownWrapper'
import { MAP_AUDIT_CATEGORY_TO_VALUE } from '../detail/const'

interface IProps {
  product: IProductSnapshot
  isInEditMode?: boolean
  isInAuditMode?: boolean
  currencySymbol: string
  finalOrder: IOrder
  currentOrder: IOrder
  cancelTips?: () => void
}

export const Product: FC<IProps> = ({
  product,
  isInEditMode,
  currentOrder,
  finalOrder,
  currencySymbol,
  cancelTips,
  isInAuditMode,
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const dropdownMenuRef = useRef<HTMLDivElement>(null)

  const issue = product.categories

  const [isDropdownExpanded, setIsDropdownExpanded] = useState<boolean>(false)
  const [dropdownPosition, setDropdownPosition] = useState<{ y: number }>({ y: 0 })

  useOutsideClick(() => setIsDropdownExpanded(false), dropdownMenuRef)

  const currentProduct = findProduct(currentOrder?.products, product.productId!)

  const label = currentProduct ? getLabel(currentProduct?.quantity, product.quantity) : LABELS.ADDED

  const diff = getDiff(currentProduct?.quantity, product.quantity)

  const onClick = (value: number) => {
    const index = R.findIndex((p) => p.productId === product.productId, finalOrder.products || [])
    const products = R.update(index, { ...product, quantity: product.quantity + value }, finalOrder.products || [])

    dispatch(
      setFinalOrder({
        ...finalOrder,
        products,
      }),
    )
  }

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value)
    Number.isNaN(value) ? toast.warn('only can type number') : value >= 0 && onClick(value - product.quantity)
  }

  const onClose = useCallback(
    (product: IProductSnapshot) => {
      const products = (finalOrder?.products || []).filter((item) => item.productId !== product?.productId)

      dispatch(
        setFinalOrder({
          ...finalOrder,
          products,
        }),
      )
    },
    [finalOrder, dispatch],
  )
  const onIssueChange = (value: AuditCategory) => {
    const index = R.findIndex((p) => p.productId === product.productId, finalOrder.products || [])
    const products = R.update(index, { ...product, categories: value, issueRequired: false }, finalOrder.products || [])

    dispatch(setFinalOrder({ ...finalOrder, products }))
    setIsDropdownExpanded(false)
    cancelTips && cancelTips()
  }

  return (
    <Container required={product.issueRequired}>
      <Thumbnail thumbnail={product.thumbnail} />
      <BaseInfo>
        {label !== LABELS.NO_CHANGE && (
          <LabelsWrap>
            <Label label={label}>
              {label}
              {label === LABELS.QUANTITY && diff}
            </Label>
            {isInAuditMode && isInEditMode ? (
              <DropdownContainer>
                <IssueLabel
                  required={product.issueRequired}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    setDropdownPosition({
                      y: e.clientY + 15,
                    })
                    setIsDropdownExpanded(!isDropdownExpanded)
                  }}
                >
                  <IssueText margin>
                    {issue ? MAP_AUDIT_CATEGORY_TO_VALUE[issue] : 'Report issue'}
                  </IssueText>
                  <ChevronIcon
                    color={product.issueRequired ? theme.colors.red : theme.colors.deepPurple}
                    direction={isDropdownExpanded ? 'up' : 'down'}
                  />
                </IssueLabel>
                {isDropdownExpanded && (
                  <DropdownWrapper ref={dropdownMenuRef} style={{ top: dropdownPosition.y }}>
                    {R.keys(MAP_AUDIT_CATEGORY_TO_VALUE).map((key) => (
                      <CheckboxContainer key={key}>
                        <input type="radio" value={key} checked={key === issue} onChange={() => onIssueChange(key)} />
                        {MAP_AUDIT_CATEGORY_TO_VALUE[key]}
                      </CheckboxContainer>
                    ))}
                  </DropdownWrapper>
                )}
              </DropdownContainer>
            ) : (
              issue && (
                <IssueLabel>
                  <IssueText>
                    {MAP_AUDIT_CATEGORY_TO_VALUE[issue]}
                  </IssueText>
                </IssueLabel>
              )
            )}
          </LabelsWrap>
        )}
        <Name title={product.name}>
          {product.name}
        </Name>
        <UnitPrice price>
          {currencySymbol}
          {product.price}
        </UnitPrice>
      </BaseInfo>
      <Info>
        <QuantityInfo>
          {isInEditMode ? (
            <NewQuantityContainer>
              <Sub onClick={() => onClick(-1)} disabled={product.quantity <= 0}>
                -
              </Sub>
              <Quantity type="text" value={product.quantity} onChange={onChange} />
              <Add onClick={() => onClick(1)}>+</Add>
            </NewQuantityContainer>
          ) : (
            <NewQuantityContainer>
              {product.quantity}
            </NewQuantityContainer>
          )}
          {diff && <PreviousQuantity diff={diff} quantity={currentProduct?.quantity} />}
        </QuantityInfo>
      </Info>
      <PriceWrap>
        <CurrentItem>
          {currencySymbol}
          {(Number(product.price) * product.quantity).toFixed(2)}
        </CurrentItem>
        {diff && (
          <OldItem>
            {currencySymbol}
            {currentProduct ? (Number(currentProduct.price) * currentProduct.quantity).toFixed(2) : 0}
          </OldItem>
        )}
      </PriceWrap>
      {isInEditMode && label === LABELS.ADDED && <CloseIcon src={CloseSvg} onClick={() => onClose(product)} />}
    </Container>
  )
}
