import dayjs from 'dayjs'
import React, {
  createRef, FC, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from 'stores'
import { OrderStatus, StatusColor } from 'stores/orders/types'
import { requestOrders, resetOrders } from 'stores/orders/actions'
import { requestStores } from 'stores/admin/actions'
import { IStore } from 'interface/admin/store'
import { history } from 'browserHistory'
import * as queryString from 'query-string'
import { CrossIcon } from 'components/icons/cross'
import { theme } from 'theme'
import { StoreSelector } from './storeSelector'
import { StatusSelector } from './statusSelector'

import {
  Container,
  BoldTableCell,
  StatusBoldTableCell,
  DateText,
  TableBody,
  TableCell,
  TableRow,
  Tag,
  TimeText,
  StatusTableCell,
  FilterContainer,
  PageTitle,
  FilterConditions,
  FilterTag,
  RemoveBtn,
} from './style'
import { SearchBar } from './searchBar/index'
import { DateSelector } from './dateSelector/index'
import { getStatusString } from './utils'

export type Nullable<T> = T | null

const mapOrderStatus = [OrderStatus.CONTESTED, OrderStatus.DRAFT, OrderStatus.PAID, OrderStatus.REVIEWED, OrderStatus.UNPAID]
const dateFormat = 'MMM. DD, YY'

export const OrderList: FC = () => {
  const dispatch = useDispatch()

  const search = queryString.parse(window.location.search) as {
    searchId: string
    status: string
    storeId: string
    start: string
    end: string
  }
  const orders = useSelector((state: IAppState) => state.order.orders || [])
  const stores = useSelector((state: IAppState) => state.admin.stores || [])
  const pagination = useSelector((state: IAppState) => state.order.pagination)
  const currencySymbol = useSelector((state: IAppState) => state.store.config.currency.symbol)

  const [dates, setDates] = useState<{ start: Nullable<Date>, end: Nullable<Date> } | null>({
    start: search.start ? new Date(search.start) : null,
    end: search.start ? new Date(search.end) : null,
  })
  const [pageSize, setPageSize] = useState<number>(100)
  const [page, setPage] = useState<number>(0)
  const [searchId, setSearchId] = useState<number | string>()
  const [status, setStatus] = useState<OrderStatus | undefined>(search.status as OrderStatus)
  const [store, setStores] = useState<IStore | undefined>(stores.filter((i) => i.storeId === search.storeId)[0])
  const [selector, setSelector] = useState<string>()

  const ScrollRef = createRef<HTMLElement>()

  const onScroll = useCallback(() => {
    if (!ScrollRef || !ScrollRef?.current?.clientHeight || !ScrollRef?.current?.scrollTop) {
      return
    }
    if (ScrollRef?.current?.scrollHeight - ScrollRef?.current?.clientHeight > ScrollRef?.current?.scrollTop) {
      // Haven't reached the bottom
    } else {
      // Has reached the bottom
      !!pagination?.next && setPage(page + 1)
    }
  }, [ScrollRef, pagination, page])

  useEffect(() => {
    let end = null
    if (dates?.end) {
      end = new Date(dates?.end.toString())
      end.setDate(end.getDate() + 1)
    }
    dispatch(
      requestOrders(
        Number(searchId) || undefined,
        dates?.start || undefined,
        end || undefined,
        page,
        status,
        store?.storeId,
        pageSize,
      ),
    )
  }, [searchId, status, dates, page, pageSize, dispatch, store])

  useEffect(() => {
    setPage(0)
    dispatch(resetOrders())
    const params = {
      status: status?.toString() ?? '',
      storeId: store?.storeId?.toString() ?? '',
      start: dates?.start?.toISOString() ?? '',
      end: dates?.end?.toISOString() ?? '',
    }

    const searchParams = new URLSearchParams(params).toString()

    history.push({
      pathname: '/retailer/orders',
      search: `?${searchParams}`,
    })
  }, [dates, status, searchId, dispatch, store])

  const handleOnItemClick = useCallback((id) => {
    history.push({
      pathname: `/retailer/orders/${id}`,
      state: {
        id,
      },
    })
  }, [])

  useEffect(() => {
    dispatch(requestStores())
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('click', (e: MouseEvent) => {
      const ids = e.composedPath().map((i) => (i as HTMLInputElement).id)
      if (!ids.includes('statusSelector') && !ids.includes('storeSelector') && !ids.includes('dateSelector')) {
        setSelector('')
      }
    })
  }, [])

  const showActiveFilters = (!!dates?.start && !!dates?.end) || !!status || !!store?.storeId

  return (
    <Container>
      <FilterContainer>
        <PageTitle>Orders List</PageTitle>
        <SearchBar setPageSize={setPageSize} setSearchId={setSearchId} searchId={searchId} />
        <DateSelector
          setPageSize={setPageSize}
          setSelected={setDates}
          title="By Date"
          setSelector={setSelector}
          selector={selector}
        />
        <StatusSelector
          selected={status}
          setSelected={setStatus}
          setPageSize={setPageSize}
          title="By Status"
          options={mapOrderStatus}
          setSelector={setSelector}
          selector={selector}
        />
        <StoreSelector
          selected={store}
          setSelected={setStores}
          setPageSize={setPageSize}
          title="By Store"
          options={stores}
          setSelector={setSelector}
          selector={selector}
        />
      </FilterContainer>
      {showActiveFilters && (
        <FilterConditions>
          {!!dates?.start && !!dates?.end && (
            <FilterTag>
              {`Date: ${dayjs(dates.start).format(dateFormat)} - ${dayjs(dates.end).format(dateFormat)}`}
              <RemoveBtn onClick={() => setDates(null)}>
                <CrossIcon color={theme.colors.grey[500]} />
              </RemoveBtn>
            </FilterTag>
          )}
          {!!status && (
            <FilterTag>
              {`Status: ${getStatusString(status)}`}
              <RemoveBtn onClick={() => setStatus(undefined)}>
                <CrossIcon color={theme.colors.grey[500]} />
              </RemoveBtn>
            </FilterTag>
          )}
          {!!store?.storeId && (
            <FilterTag>
              {`Store: ${store.storeName}`}
              <RemoveBtn onClick={() => setStores(undefined)}>
                <CrossIcon color={theme.colors.grey[500]} />
              </RemoveBtn>
            </FilterTag>
          )}
        </FilterConditions>
      )}
      <div
        style={{
          height: '100%',
          padding: '0 18px',
          overflow: 'hidden',
        }}
      >
        <TableRow isTh>
          <BoldTableCell>Receipt ID</BoldTableCell>
          <BoldTableCell>Store</BoldTableCell>
          <BoldTableCell>Total</BoldTableCell>
          <BoldTableCell>Date</BoldTableCell>
          <StatusBoldTableCell>Status</StatusBoldTableCell>
        </TableRow>
        <TableBody ref={ScrollRef} onScroll={onScroll}>
          {orders.map((receipt, index) => (
            <TableRow
              key={index}
              onClick={() => {
                handleOnItemClick(receipt.id)
              }}
            >
              <TableCell>
                {receipt.id}
              </TableCell>
              <TableCell>
                {stores.find((value) => String(value.storeId) === String(receipt.storeId))?.storeName}
              </TableCell>
              <TableCell>
                {currencySymbol}
                {receipt.status === OrderStatus.CONTESTED || receipt.status === OrderStatus.REVIEWED
                  ? receipt.contest?.reviewedTotalPrice || receipt.contest?.originalTotalPrice || receipt.totalPrice
                  : receipt.totalPrice}
              </TableCell>
              <TableCell>
                <div>
                  <DateText>
                    {dayjs(receipt.createdAt).format('MMM D, YYYY')}
                  </DateText>
                  <TimeText>
                    &nbsp;
                    {dayjs(receipt.createdAt).format('h:mm A')}
                  </TimeText>
                </div>
              </TableCell>
              <StatusTableCell>
                <Tag color={StatusColor[receipt.status as unknown as OrderStatus]}>
                  {getStatusString(receipt.status)}
                </Tag>
              </StatusTableCell>
            </TableRow>
          ))}
        </TableBody>
      </div>
    </Container>
  )
}
